import React from 'react';

import {ThreeItemsOrganicGrid} from '@components/OrganicImageGrid';
import Section from './StyledSection';
import {ThemeProvider} from '@styles';

export default function ThreeItemsOrganicGridSection({...props}): JSX.Element {
  return (
    <ThemeProvider
      theme={{
        maxPadding: '8em 0',
        maxWidth: '80%',
      }}
    >
      <Section>
        <ThreeItemsOrganicGrid {...props} />
      </Section>
    </ThemeProvider>
  );
}
