import React from 'react';
import useHover from '@hzdg/use-hover';

import {ForwardLink} from '@components/Link/ArrowLink';
import {Headline, Paragraph} from '@components/typography';
import {styled, Colors, ThemeProvider, Sizes, theme, Fonts} from '@styles';
import {ResponsiveContainer} from '@components/layout';

export interface ApproachSectionCalloutProps {
  headerBlock: {
    header: string;
    subheader: string;
  };
  pagelinkBlock: {
    title: string;
    page: {
      url: string;
    };
  };
  alignment: 'center' | 'right' | 'left';
}

const CalloutContainer = styled.div.withConfig({
  componentId: 'approachSectionCalloutContainer'
})`
  background: ${theme.bg};
  padding: ${theme.minPadding};
  max-width: ${Sizes.ExtraWide}px;
  margin: 1em auto 0;
  display: flex;
  flex-direction: column;
  align-items: ${({alignment}: {alignment: string}) =>
    alignment === 'right'
      ? 'flex-end'
      : alignment === 'center'
      ? 'center'
      : 'flex-start'};

  .wide & {
    margin: 4em auto 0;
  }
`;

const Grid = styled(ResponsiveContainer).withConfig({
  componentId: 'approachSectionCalloutGrid'
})`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  .wide & {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
  }
`;

const StyledHeadline = styled(Headline).withConfig({
  componentId: 'approachSectionCalloutHeadline'
})`
  width: 100%;
  max-width: 512px;
`;

const Content = styled.div.withConfig({
  componentId: 'approachSectionCalloutContent'
})`
  width: 100%;
  max-width: 502px;
`;

const LinkContainer = styled.div.withConfig({
  componentId: 'approachSectionCalloutLinkContainer'
})`
  margin: 1.5em 0;
`;

const StyledLink = styled.div.withConfig({
  componentId: 'approachSectionCalloutLink'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
`;

export default function ApproachSectionCallout({
  headerBlock,
  pagelinkBlock,
  alignment = 'left',
}: ApproachSectionCalloutProps): JSX.Element {
  const {header, subheader} = headerBlock;
  const {title, page} = pagelinkBlock;
  const [isHover, hoverProps] = useHover();
  return (
    <ThemeProvider
      theme={{
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.Transparent,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
      }}
    >
      <CalloutContainer alignment="center">
        <Grid>
          <StyledHeadline>{header}</StyledHeadline>
          <Content>
            <Paragraph center={alignment === 'center'}>{subheader}</Paragraph>
            {pagelinkBlock && pagelinkBlock.page && pagelinkBlock.page.url && (
              <LinkContainer>
                <ForwardLink
                  isHover={isHover}
                  filled
                  href={page.url}
                  {...hoverProps}
                >
                  <StyledLink>{title}</StyledLink>
                </ForwardLink>
              </LinkContainer>
            )}
          </Content>
        </Grid>
      </CalloutContainer>
    </ThemeProvider>
  );
}
